.headerContainer {
  background-color: var(--primary-900-color);
  color: #fff;
}

.topBarLeft:first-child {
  color: #a7a7c3;
}

.topBarRight {
  composes: topBarRight from './Header.module.css';
}

.topBarLeft > .topBarItem {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.mainBarContainer {
  composes: mainBarContainer from './Header.module.css';
}

.logo {
  width: 90px;
}

@media (max-width: 768px) {
  .logo {
    width: 60px;
  }

  .mobileMiddle {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 30px;
    padding-bottom: 3rem;
    border-bottom: 1px solid var(--header-divider-color);
  }

  .mainBarContainer {
    height: 64px;
  }

  .button {
    font-size: 16px;
    line-height: 17.6px;
    letter-spacing: 0.09em;
    font-weight: 700;
    margin-bottom: -32px;
  }

  .topBarRight {
    display: none;
  }
  .topBarItem {
    line-height: 16px;
  }
}
