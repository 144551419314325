  .styledTile {
    position: relative;
    height: 100%;
    max-width: 19rem;
    width: 305px;
  }
  
  .styledTileWide {
    border-radius: 0.75rem;
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 1.5rem;
    max-width: 28rem;
  }
  
  @media (min-width: 768px) {
    .styledTileWide {
      max-width: 45rem;
      padding: 2rem;
      border-radius: 0.5rem;
    }
  }
  
  .photoWrapper {
    height: 0;
    padding-top: calc(1 * 100%);
    overflow: hidden;
    position: relative;
    border-radius: 0.5rem;
  }
  
  .photoWrapperMobile {
    padding-top: calc(9 / 16 * 100%);
  }
  
  .photoWrapperDesktop {
    padding-top: calc(1 * 100%);
  }
  
  .widePhotoWrapper {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
    max-width: 25rem;
    overflow: hidden;
    position: relative;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .heading6 {
    font-weight: 500;
  }

  .image {
    object-fit: cover;
    object-position: center;
  }

  @media (min-width: 500px) {
    .widePhotoWrapper {
      aspect-ratio: 1 / 1;
      margin-right: 2.5rem;
      margin-bottom: 0;
      min-width: 186px;
      max-width: 100%;
      height: auto;
    }
  }
  
  .photo, .widePhoto {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }
  
  .infoBox {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    flex: 1;
    color: var(--gray-1000);
    width: 100%;
  }
  
  .infoRow {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.75rem;
    gap: 1rem;
    flex: 1;
  }
  
  .marginBottomHalf {
    margin-bottom: 0.5rem;
  }
  
  .marginBottomThreeQuarters {
    margin-bottom: 0.75rem;
  }
  
  .detailsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .title, .infoTitle {
    font-weight: 700;
    text-transform: uppercase;
  }
  
  .infoSubtitle {
    font-weight: regular;
  }
  
  .eyebrow {
    color: var(--gray-600);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .button {
    font-weight: semiBold;
  }
  