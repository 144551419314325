.rightMobile {
  display: none;
}

@media (max-width: 768px) {
  .rightMobile {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;

    .hamburger {
      height: 24px;
      background: transparent;
      display: block;
      filter: var(--header-icon-filter-color);
    }
  }

  .overlayContainer {
    position: fixed;
    padding: 24px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--background-color, #fff);

    z-index: 1000;

    overflow-y: auto;

    .overlay {
      display: flex;
      flex-direction: column;
      justify-content: left;
      align-items: left;
      gap: 48px;
      height: 100vh;
      max-height: 100vh;

      .closeButton {
        position: absolute;
        top: 40px;
        right: 26px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 14px;
        transform: scaleX(1.6);
        font-weight: 400;
        background: unset;
         filter: var(--header-icon-filter-color);
      }
    }
  }

  .collapseSection {
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-bottom: 16px;
  }

  .collapseSection img {
     filter: var(--header-icon-filter-color);
  }
}
