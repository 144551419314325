.headerContainer {
}

.topBarContainer {
  display: flex;
  flex-direction: row;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: var(--text-header-color);
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--header-divider-color);
  font-weight: 500;
  font-size: 12px;
}

.topBarLeft {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}

.topBarRight {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: center;
}

.topBarItem {
  display: flex;
  align-items: center;
}

.mainBarContainer {
  display: flex;
  flex-direction: row;
  height: 88px;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 2px var(--shadow-color);
  width: 100%;
}

.logo {
  width: 200px;
}

.left {
}

.middle {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 40px;
  height: 100%;
}

.right {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px;
  height: 100%;
}

.middleItem,
.icon {
  display: flex;
  align-items: center;
  text-align: center;
  height: 100%;
  filter: var(--header-icon-filter-color);
}

.middleItemAnimation {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
  text-align: center;
  height: 100%;
  position: relative;
  font-weight: 500;
}

.middleItemAnimation:hover::after {
  transform: scaleY(1);
}

.middleItemAnimation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleY(0);
  height: 3px;
  border-radius: 3px 3px 0 0;
  bottom: 0;
  left: 0;
  background-color: #f6b700;
  transform-origin: bottom;
  transition:
    transform 0.2s,
    opacity 0.2s;
}

@media (max-width: 768px) {
  .topBarContainer {
    display: none;
  }

  .middle {
    display: none;
  }

  .right {
    display: none;
  }

  .middleItem,
  .middleItemAnimation {
    text-align: left;
    height: unset;
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.03em;
    font-weight: 700;
  }
}

.userButton {
  background: none;
  height: max-content;
  align-self: center;
  cursor: pointer;
}

.icon {
  height: 24px;
  width: 24px;
}

.userButton:focus-visible {
  outline: none;
}
