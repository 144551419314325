  .iconWrapper {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--toast-success-background-color);
    color: var(--toast-success-font-color);
  }
  
  .iconWrapperInfo {
    align-self: flex-start;
    margin-top: 1rem;
  }
  
  @media (min-width: 768px) {
    .iconWrapperInfo {
      align-self: unset;
      margin-top: unset;
    }
  }
  
  .xIcon {
    height: 0.875rem;
    width: 0.875rem;
    cursor: pointer;
  }
  
  .toastIcon {
    height: 1.25rem;
    width: 1.25rem;
  }
  
  .label {
    margin: 0 0.625rem;
    flex-grow: 1;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5;
  }
  
  .styledToast {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 1rem;
    margin: 0 0 1rem 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    border-left-width: 3px;
    height: 3rem;
    color: inherit;
    background-color: inherit;
    opacity: 0;
    animation: fadeIn 0.3s ease forwards;
  }
  
  .toasts {
    min-height: 0;
    height: auto;
    animation: expand 0.15s ease-in-out;
    transition: height 0.15s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .success {
    border-left-color: var(--toast-success-left-color);
    color: var(--toast-success-font-color);
    background-color: var(--toast-success-background-color);
  }
  
  .error {
    border-left-color: var(--toast-error-left-color);
    color: var(--toast-error-font-color);
    background-color: var(--toast-error-background-color);
  }
  
  .info {
    border-left-color: var(--toast-info-left-color);
    color: var(--toast-info-font-color);
    background-color: var(--toast-info-background-color);
  }
  
  .largeToast {
    height: 3.5rem;
  }
  
  .smallToast {
    height: 3rem;
  }
  