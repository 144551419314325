.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.container > summary::-webkit-details-marker {
  display: none;
}

.icon {
  width: 20px;
  height: 20px;
}

.icon img {
  transform: rotate(-90deg);
  transition: 0.1s ease;
}

details[open] .icon img {
  transform: rotate(0deg);
}

.border-bottom {
  border-bottom: 1px solid #d3d3d3;
}

.border-top {
  border-top: 1px solid #d3d3d3;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: var(--mobile-footer-link-color, #000);
  cursor: pointer;
  border-top: 2px solid #f5f5f5;
}

.big {
  padding: 1.875rem 0;
}

.regular,
.small {
  padding: 1.5rem 1rem;
}

.minimal {
  padding-top: 1.125rem;
  padding-bottom: 1rem;
}

.title {
  display: flex;
  align-items: center;
}

.title span {
  line-height: 1;
  font-weight: 600;
}
