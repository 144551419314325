.buttonsContainer {
  display: flex;
  flex-direction: column;
}

.menuButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 2.5rem;
  border-radius: 0.5rem;

  background: none;
}

.menuButtonContainer:focus-visible {
  outline: none;
}

.menuButtonContainer:hover {
  cursor: pointer;
  background-color: #fffbee;

  & .menuButtonIcon {
    filter: invert(70%) sepia(69%) saturate(4229%) hue-rotate(11deg) brightness(104%) contrast(107%);
  }

  & .menuButtonLabel {
    color: #242424;
  }
}

.menuButtonIcon {
  margin: 0 0.75rem;
  filter: invert(51%) sepia(2%) saturate(142%) hue-rotate(318deg) brightness(83%) contrast(89%);
}

.menuButtonLabel {
  color: #6e6e6e;
  font-weight: 500;
}

.userContainer {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  padding: 0 1rem;
}

.usernameLabel {
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0;
  font-weight: 600;
  color: #242424;
}

.mailLabel {
  font-size: 0.875rem;
  line-height: 1.2;
  letter-spacing: -0.02em;
  font-weight: 500;
  color: #6e6e6e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
