.sliderContainer {
  border: none;
  padding: var(--button-large-padding);
  border-radius: var(--button-border-radius);
  cursor: unset;
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  max-width: 600px; 
  aspect-ratio: 343 / 161;
  justify-self: flex-end;
}

.alice-carousel, .alice-carousel__wrapper, .alice-carousel__stage {
  width: 100%;
  height: 100%;
}

.image { 
  width: calc(100vw - 32px);
  max-width: 600px;
}

.alice-carousel__stage-item {
  @media (min-width: 990px) {
    width: unset;
    height: 476px;
  }

  @media (min-width: 1200px) {
    width: 412px;
    height: 560px;
  }
}

.alice-carousel__stage {
  display: flex;
  flex-direction: row;
}

.alice-carousel__dots {
  display: none;  
}

.alice-carousel__prev-btn-item, .alice-carousel__next-btn-item {
  display: none;
}

.dot, .activeDot {
  width: 10px;
  height: 10px;
  background-color: #000;
  opacity: 0.5;
  border-radius: 50%;
  transition: .2s;
}

.dot:hover {
  opacity: 0.8;
}

.activeDot {
  opacity: 1;
}

.alice-carousel__dots, .alice-carousel__next-btn-item, .alice-carousel__prev-btn-item {
  display: none;
}

.dotsContainer {
  width: 100%;
  z-index: 10;
  height: auto;
  padding: 24px;
  display: flex;
  background-color: transparent; 
  bottom: 100px;
  left: 0;
  justify-content: flex-start;
  align-items: flex-end;
  position: absolute;
  gap: 0.625rem; 
  max-width: 600px;
  aspect-ratio: 343 / 161;

  @media (min-width: 400px) {
    padding: 32px;
  }
  
  @media (min-width: 990px) {
    bottom: 24px;
    justify-content: center;
    padding: 0;
    height: 460px;
    aspect-ratio: 412 / 560;
  }

  @media (min-width: 1200px) {
    height: 560px;
  }
}

.sliderContainer, .image, .alice-carousel {
  @media (min-width: 990px) {
    justify-self: flex-end;
    width: 350px;
    height: 476px;
  }

  @media (min-width: 1200px) {
    width: 412px;
    height: 560px;
  }
}
