.section {
  composes: section from './StatisticsSection.module.css';
  padding: 0;
  justify-content: flex-start;
  gap: 1.25rem;

  padding: 0;
  margin: 0;
  width: 100%;
  justify-content: space-between;
  justify-self: flex-start;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.item {
  composes: item from './StatisticsSection.module.css';
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: flex-start;
  width: 96px;
  gap: 4px;
}

.item_title {
  composes: item_title from './StatisticsSection.module.css';
  color: var(--primary-900-color);
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5rem;
  letter-spacing: 0.03em;
  text-align: left;
}

.item_description {
  composes: description from './StatisticsSection.module.css';
  font-size: 0.75rem;
  line-height: 1.125rem;
  text-align: left;
}

@media (min-width: 768px) {
  .section {
    gap: 0;
  }

  .item {
    width: 150px;
    flex-basis: auto;
    max-width: none;
  }
}

@media (max-width: 768px) {
  .section {
    flex-direction: column;
  }

  .item {
    width: 100%;
    gap: 0.5rem;
    flex-basis: 0;
    max-width: unset;
    flex-direction: row;
    align-items: center;
  }

  .item_title{
    width: 64px;
    text-align: center;
  }
}
