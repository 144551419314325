.authButtonsContainer {
  display: flex;
  column-gap: 0.5rem;

  .signButton {
    height: min-content;
    align-self: center;

    font-size: 0.875rem;
    text-transform: capitalize;
  }
}

@media (max-width: 768px) {
  .authButtonsContainer {
    flex-direction: column-reverse;
    row-gap: 1rem;

    .signButton {
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
      width: 100%;
      font-size: 1rem;
      min-height: 56px;
      font-weight: 700;
      letter-spacing: 0.09em;
      box-sizing: border-box;
    }
  }
}
