.container {
}

.control {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.label {
  color: var(--select-label-color);
}

.value {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  color: var(--select-value-color);
  font-weight: 700;
  background-color: unset;
  cursor: pointer;
}

.content {
  padding: 0.4rem;
  background-color: var(--select-background-color);
  border-radius: 0.5rem;
  box-shadow: 0px 8px 8px var(--shadow-color);
}

.icon {
  width: 20px;
  height: 20px;
}

.item {
  /* for some reason by default its Times New Roman and i have problem unseting it to inherit */
  font-family: var(--font-poppins);
  cursor: pointer;
  padding: 0.8rem 1rem;
  border-radius: 0.25rem;
}

.item:hover {
  background-color: var(--select-item-background-color-hover);
}

.item[data-state='checked'] {
  background-color: var(--select-item-background-color-selected);
  color: var(--select-item-color-selected);
}
