.container {
  display: flex;
  flex-direction: column;
}

.topContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-bottom: 2rem;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
  row-gap: 80px;
  padding: 16px;
  margin-top: 2rem;
}

.sectionTitle {
  letter-spacing: -0.56px;
}

.item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.image {
  width: 64px;
  height: 64px;
}

.title,
.description,
.subtitle {
  max-width: 18.5rem;
}

.button {
  flex-shrink: 0;
}

.showOnMobile {
  display: none;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.title {
  font-weight: 500;
  white-space: pre-line;
}

.logo {
  height: 32px;
  width: 100px;
}

@media (max-width: 600px) {
  .button {
    justify-self: center;
  }

  .grid {
    grid-template-columns: 1fr;
  }

  .topContainer {
    margin-bottom: 2rem;
  }

  .hideOnMobile {
    display: none;
  }

  .showOnMobile {
    justify-self: stretch;
    display: flex;
  }

  .item {
    flex-direction: row;
  }

  .text {
    margin-left: 1rem;
  }

  .title,
  .description {
    margin: 0;
  }
}
