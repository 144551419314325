.container {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 2rem;
  gap: 2rem;
}

.mainContainer {
  display: flex;
  flex-wrap: wrap;
}

.partnersContainer {
  padding: 2rem 0;
  border-top: 2px solid var(--footer-partner-section-border-color);
  border-bottom: 2px solid var(--footer-partner-section-border-color);
}

.items {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin-top: 2rem;
}

.itemsMobile {
  display: none;
}

.itemMobile {
  display: flex;
  flex-direction: column;
}

.linkMobile {
  width: 100%;
  display: block;
  padding-bottom: 1rem;
  font-weight: 500;
  cursor: pointer;
}

.itemTitle {
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 500;
}

.item {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 9rem;
}

.link {
  color: #000;
  text-decoration: none;
}

.newsLetterContainer {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  max-width: 400px;
  gap: 2rem;
}

.newsLetterTitle {
  font-weight: 500;
  font-size: 1.25rem;
}

.newsLetterSubtitle {
  display: none;
}

.newsLetterInput {
  max-width: 100%;
}

.newsLetterInputContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.newsLetterDescription {
  font-size: 14px;
  line-height: 20px;
  white-space: pre-wrap;
}

.newsLetterDescription a,
.newsLetterDescription button {
  background-color: #fff;
  color: var(--accent-color);
}

.bulletpointContainer {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.bulletpointList {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.bulletpointTitle {
  font-size: 0.9375rem;
}

.bulletpointItem {
  font-size: 0.75rem;
}

.bulletpointIcon {
  margin-right: 0.25rem;
}

.logo {
  height: 2.5rem;
}

.copyright {
  font-size: 14px;
}

.socialsContainer {
  display: flex;
  align-items: center;
}

.socials {
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  margin-left: auto;
}

.social {
  height: 1.5rem;
  width: 1.5rem;
}

.partnersContainer {
  display: flex;
  gap: 2rem;
}

.partnerTitle {
  font-size: 14px;
}

.partnerLogo {
  height: 2.5rem;
  object-fit: contain;
}

.partner {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 4rem;
  width: 10rem;
}

@media (max-width: 768px) {
  .container,
  .mainContainer {
    flex-direction: column;
    flex-wrap: wrap;
    padding: 2rem 1rem 2.5rem 1rem;
    gap: 0;
  }

  .mainContainer {
    padding-top: 2.5rem;
  }

  .items {
    display: none;
  }

  .itemsMobile {
    display: flex;
    flex-direction: column;
  }

  .socialsContainer {
    flex-direction: column-reverse;
    gap: 1.5rem;
    align-items: baseline;
    margin: 1.5rem 0;
  }

  .socials {
    margin-left: 0;
  }

  .newsLetterText {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .newsLetterSubtitle {
    display: block;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .newsLetterInputContainer {
    flex-direction: column;
  }

  .newsLetterInput {
    width: unset;
  }

  .item {
    width: 9rem;
  }

  .items {
    flex-wrap: wrap;
  }

  .logoContainer {
    margin: 0;
  }
}

@media (max-width: 600px) {
  .partnersContainer {
    flex-direction: column;
    align-items: center;
  }

  .newsLetterContainer {
    margin-left: unset;
    max-width: unset;
  }
}
