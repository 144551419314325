.wrapper {
  background-color: var(--section-background-color);
}

.topContainer {
  composes: topContainer from './WeOfferSection.module.css';
  display: flex;
  align-items: space-between;
  gap: 4rem;
  margin: 0;
}

.sectionTitle {
  composes: sectionTitle from './WeOfferSection.module.css';
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 2.75rem;
  letter-spacing: 0.02em;
  text-align: left;

  @media (min-width: 600px) {
    width: 70%;
  }

  @media (min-width: 1100px) {
    width: 50%;
  }
}

.subtitle {
  composes: subtitle from './WeOfferSection.module.css';
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.875rem;
  letter-spacing: 0.02em;
  text-align: left;
  @media (min-width: 1200px) {
    max-width: 30rem;
  }
}

.image {
  composes: image from './WeOfferSection.module.css';
  filter: var(--icon-filter-color);
}

.itemNumber {
  font-size: 3rem;
  font-weight: 800;
  line-height: 3.25rem;
  letter-spacing: -0.02em;
  text-align: left;
  filter: var(--icon-filter-color);
}

.title {
  composes: title from './WeOfferSection.module.css';
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5rem;
  letter-spacing: 0.03em;
  text-align: left;
}

.description {
  composes: description from './WeOfferSection.module.css';
  font-size: 1.25rem;
  line-height: 1.875rem;
  letter-spacing: -0.02em;
  text-align: left;
}

.button {
  composes: button from './WeOfferSection.module.css';
  display: none;
}

.grid {
  composes: grid from './WeOfferSection.module.css';
  margin-top: 96px;

  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));

  @media (min-width: 990px) {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    column-gap: 20px;
  }
}

@media (max-width: 600px) {
  .topContainer {
    flex-direction: column;
    gap: 1rem;
  }

  .sectionTitle {
    line-height: 1.75rem;
    font-size: 1.75rem;
  }

  .subtitle {
    font-size: 1rem;
    max-width: unset;
  }

  .grid {
    margin-top: 2.5rem;
    row-gap: 2.5rem;
    padding: 0;
  }

  .itemNumber {
    font-size: 1.75rem;
    line-height: 2rem;
  }

  .title {
    font-size: 1rem;
  }

  .description {
    font-size: 1rem;
  }

  .image {
    width: 48px;
    height: 48px;
  }
}
