.section {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  row-gap: 2.5rem;
}

.item {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  text-align: center;
  flex-basis: calc(50% - 10px);
  max-width: calc(50% - 10px);
}

.item_title {
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--accent-color)
}

.item_description {
  line-height: 150%;
}

@media (min-width: 768px) {
  .section {
    gap: 0;
  }

  .item {
    width: 200px;
    flex-basis: auto;
    max-width: none;
  }
}
