.container {
  composes: container from './Footer.module.css';
  flex-direction: column;
  font-family: var(--font-poppins);
  color: #242424;
}

.logo {
  composes: logo from './Footer.module.css';
  width: 252px;
  height: auto
}

.itemTitle {
  composes: itemTitle from './Footer.module.css';
  font-size: 15px;
  font-weight: 600;
  color: #242424;
  margin-bottom: 24px;
}

.item {
  composes: item from './Footer.module.css';
  font-size: 12px;
}



