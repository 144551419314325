.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--button-background-color);
  color: var(--text-accent-color);
  border: none;
  padding: var(--button-large-padding);
  border-radius: var(--button-border-radius);
  cursor: pointer;
  font-size: var(--button-large-font-size);
  text-transform: uppercase;
}

.button img {
  filter: var(--button-filter-color);
}

.button:hover {
  background-color: var(--button-background-hover-color);
}

.button:disabled {
  background-color: var(--button-background-hover-color);
}

.iconRight {
  margin-left: 8px;
  width: 12px;
  height: 12px;
  display: var(--button-arrow-display);
  flex-shrink: 0;
}

.small {
  padding: var(--button-small-padding);
  font-size: var(--button-small-font-size);

  & .iconRight {
    width: 20px;
    height: 20px;
  }
}

.ghost {
  background-color: transparent;
  color: var(--text-body-color);
}

.ghost:hover {
  background-color: transparent;
}

.outlined {
  background-color: transparent;
  color: var(--text-body-color);
  border: 1px solid var(--text-body-color);
}

.outlined:hover {
  background-color: transparent;
}
