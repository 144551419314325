.styledTile {
  composes: styledTile from './ReservationOfferCard.module.css';
  height: max-content;
}

.photoWrapper {
  composes: photoWrapper from './ReservationOfferCard.module.css';
  border-radius: 0;
}

.infoRow{
  composes: infoRow from './ReservationOfferCard.module.css';
  gap: 0.5rem;
}

.infoTitle {
  composes: infoTitle from './ReservationOfferCard.module.css';
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5rem;
  letter-spacing: 0.03em;
  text-align: left;
}

.eyebrow {
  composes: eyebrow from './ReservationOfferCard.module.css';
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: left;
}
