.wrapper {
  background-color: var(--section-background-color);
  color: #fff;
}

.topContainer {
  composes: topContainer from './WeOfferSection.module.css';
  margin-bottom: 64px;
  display: flex;
  align-items: flex-start;
  /* padding: 0 1rem; */
  gap: 4rem;
}

.sectionTitle {
  composes: sectionTitle from './WeOfferSection.module.css';
  font-size: 40px;
  text-align: left;

  @media (min-width: 600px) {
    width: 70%;
  }

  @media (min-width: 1100px) {
    width: 60%;
  }
}

.image {
  composes: image from './WeOfferSection.module.css';
  filter: brightness(0) saturate(100%) invert(67%) sepia(81%) saturate(345%) hue-rotate(6deg) brightness(109%) contrast(88%);
}

.logo {
  composes: logo from './WeOfferSection.module.css';
  margin-bottom: 48px;
}

.text {
  composes: text from './WeOfferSection.module.css';
  margin-left: 0;
  gap: 24px;
  margin-top: 28px;
  padding-right: 3.5rem;
}

.itemNumber {
  min-width: 60px;
  font-weight: 700;
  color: #f0cf4a;
  font-size: 48px;
}

.title {
  composes: title from './WeOfferSection.module.css';
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 700;
  max-width: unset;
  width: 100%;
}
.subtitle {
  composes: subtitle from './WeOfferSection.module.css';

  @media (min-width: 1200px) {
    max-width: 30rem;
  }
}

.grid {
  composes: grid from './WeOfferSection.module.css';
  padding: 1rem 0;
}

.description {
  composes: description from './WeOfferSection.module.css';
  max-width: unset;
  width: 100%;
}

.button {
  composes: button from './WeOfferSection.module.css';
  display: none;
}

.grid {
  composes: grid from './WeOfferSection.module.css';
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));

  @media (min-width: 990px) {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    column-gap: 20px;
  }
}

.accented {
  background-color: var(--section-background-color);
}

@media (max-width: 600px) {
  .topContainer {
    width: 100%;
    margin-bottom: 16px;
    padding: 0;
  }

  .topContainer,
  .item {
    max-width: 100%;
  }

  .image {
    height: 48px;
    width: 48px;
  }

  .text {
    gap: 8px;
    margin-top: 0;
    padding-right: 0;
  }

  .item {
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
  }
  .itemNumber {
    min-width: 48px;
  }

  .title {
    font-size: 18px;
    line-height: 27px;
    margin-top: 0;
    width: 100%;
  }

  .sectionTitle {
    font-size: 28px;
    line-height: 30.8px;
  }

  .subtitle {
    font-size: 18px;
    line-height: 27px;
  }

  .description {
    font-size: 18px;
    line-height: 27px;
    letter-spacing: -0.02em;
    max-width: 22rem;
  }

  .grid {
    gap: 32px;
    padding: 0;
  }
  .itemNumber {
    font-size: 28px;
    line-height: 30.8px;
  }
}

@media (max-width: 990px) {
  .topContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .subtitle {
    max-width: unset;
  }
}
