.logoContainer {
  margin: 1rem 0 0 1rem;
}

.items {
  composes: items from './Footer.module.css';
  gap: 1.25rem;
  width: 50%;
  align-items: stretch;
}

.newsLetterContainer {
  composes: newsLetterContainer from './Footer.module.css';
}

.item {
  composes: item from './Footer.module.css';
  gap: 14px;
  align-self: stretch;
  width: unset;
  padding-right: 1.5rem;
}
.itemTitle {
  composes: itemTitle from './Footer.module.css';
  font-weight: 700;
  margin-bottom: 10px;
  min-height: 40px;
}

.socialsContainer {
  composes: socialsContainer from './Footer.module.css';
}

.link {
  composes: link from './Footer.module.css';
  color: inherit;
  font-size: 14px;
  line-height: 20px;
}

.newsLetterTitle {
  composes: newsLetterTitle from './Footer.module.css';
  font-weight: 700;
}

.newsLetterButton {
  font-size: 15px;
  line-height: 16.5px;
  letter-spacing: 0.09em;
  font-weight: 700;
}

.newsLetterDescription {
  composes: newsLetterDescription from './Footer.module.css';
  color: #4d4d4d;
}

.linkMobile {
  composes: linkMobile from './Footer.module.css';
  color: var(--footer-link-color);
}

.partnersContainer {
  composes: partnersContainer from './Footer.module.css';
  gap: 4rem;
}

.partner {
  composes: partner from './Footer.module.css';
  width: fit-content;
}

.partnerTitle {
  font-size: 14px;
  line-height: 20px;
  color: #4d4d4d;
}

.itemsMobile {
  composes: itemsMobile from './Footer.module.css';
  margin-bottom: 1rem;
}

.copyright {
  font-size: 14px;
  line-height: 20px;
  color: #4d4d4d;
}

@media (min-width: 768px) {
  .logoContainer {
    margin: 3rem 0 0;
  }
}

@media (max-width: 600px) {
  .partnersContainer {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
    gap: 2.5rem;
  }

  .newsLetterContainer {
    gap: 1.5rem;
  }

  .socialsContainer {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
