.userMenuContainer {
  display: flex;
  flex-direction: column;

  border-radius: 0.75rem;
  background: #ffffff;

  width: 16rem;
  padding: 1.5rem 1rem;
  box-shadow:
    0px 10px 20px var(--shadow-color),
    0px 2px 6px var(--shadow-color),
    0px 0px 1px var(--shadow-color);
}

.separator {
  width: 90%;
  margin: 1rem auto;
  height: 1px;
  background: var(--separator-color);
}
