.section {
  composes: section from './StatisticsSection.module.css';
  padding: 0;
  width: 100%;
  max-width: 470px;
  display: flex;
  justify-content: space-between;
  justify-self: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  font-family: inherit;
  margin: 0;
}

.item {
  composes: item from './StatisticsSection.module.css';
  width: 96px;
  gap: 4px;
  display: flex;
  align-items: flex-start;
}

.item_title {
  composes: item_title from './StatisticsSection.module.css';
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  display: flex;
  align-items: flex-start;
}

.item_description {
  composes: description from './StatisticsSection.module.css';
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  color: #fff;
}

@media (max-width: 600px) {
  .section {
    gap: 20px;
    flex-direction: column;
    width: 100%;
    align-items: stretch;
  }
  .item {
    flex-direction: row;
    align-items: center;
    max-width: unset;
    width: 100%;
  }
  .item_title {
    font-size: 18px;
    width: 64px;
    justify-content: center;
  }
  .item_description {
    flex: 1;
  }
}
