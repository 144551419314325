.logoContainer {
  margin: 1rem 0 0 1rem;
}

@media (min-width: 768px) {
  .logoContainer {
    margin: 1rem 0 0;
  }
}

.logo {
  height: 6rem;
}
