.accented {
  background-color: var(--section-background-color);
}

.topContainer {
  composes: topContainer from '../WeOfferSection.module.css';
  margin-bottom: 64px;
  display: flex;
  padding: 0 1rem;
  gap: 4rem;
}

.sectionTitle {
  composes: sectionTitle from '../WeOfferSection.module.css';
  text-align: left;

  @media (min-width: 600px) {
    width: 70%;
  }

  @media (min-width: 1100px) {
    width: 50%;
  }
}

.subtitle {
  composes: subtitle from '../WeOfferSection.module.css';
  @media (min-width: 1200px) {
    max-width: 25rem;
  }
}

.button {
  display: none;
}

.grid {
  composes: grid from '../WeOfferSection.module.css';
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));

  @media (min-width: 990px) {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    column-gap: 20px;
  }
}

/* --------------------------- item ---------------------------*/
.item {
  justify-items: center;
}

.image {
  width: 100%;
  max-width: 260px;
}

.text {
  composes: text from '../WeOfferSection.module.css';
  margin-left: 0;
  gap: 24px;
  margin-top: 28px;
}

.title {
  composes: title from '../WeOfferSection.module.css';
  font-size: 1.25rem;
  font-weight: 800;
  max-width: 20rem;
}

.description {
  composes: description from '../WeOfferSection.module.css';
  max-width: 18rem;
  color: #8b8b8b;
  font-size: 1rem;
  text-align: center;
}
/* --------------------------- item ---------------------------*/

@media (max-width: 600px) {
  .topContainer {
    width: 80%;
    margin-bottom: 16px;
    padding: 0;
  }

  .topContainer,
  .item {
    max-width: 100%;
  }

  .image {
    height: 48px;
    width: 48px;
  }

  .text {
    gap: 8px;
    margin-top: 0;
  }

  .item {
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
  }

  .title {
    margin-top: 0;
    width: 100%;
  }

  .description {
    max-width: 22rem;
  }

  .grid {
    gap: 32px;
    padding: 0;
  }
}

@media (max-width: 990px) {
  .topContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .subtitle {
    max-width: unset;
  }
}
