.headerContainer {
  composes: container from './Header.module.css';
  font-family: var(--font-poppins);
}

.topBarItem {
  composes: topBarItem from './Header.module.css';
  color: var(--text-body-color);
  font-size: 0.875rem;
}

.logo {
  width: 120px;
}
