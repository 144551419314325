.container {
  composes: container from './Footer.module.css';
  gap: 2.5rem;
}

.logo {
  composes: logo from './Footer.module.css';
  width: 120px;
}

.items {
  composes: items from './Footer.module.css';
  margin: 0;
}

.itemsMobile {
  composes: itemsMobile from './Footer.module.css';
}


.itemTitle {
  composes: itemTitle from './Footer.module.css';
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;
  text-align: left;
}

.link {
  composes: link from './Footer.module.css';
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-align: left;
}

.newsLetterContainer {
  composes: newsLetterContainer from './Footer.module.css';
  max-width: 530px;
}

.newsLetterButton {
  composes: newsLetterButton from './Footer.module.css';
  width: 140px;
}

.socialsContainer {
  composes: socialsContainer from './Footer.module.css';
  align-self: flex-start;
}

@media (max-width: 600px) {
  .container{
    composes: container from './Footer.module.css';
    gap: 1.5rem;
  }

  .mainContainer {
    composes: mainContainer from './Footer.module.css';
    padding: 0;
  }

  .itemsMobile {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .itemsMobile summary {
    margin-bottom: 2rem;
  }

  .newsLetterContainer {
    composes: newsLetterContainer from './Footer.module.css';
    gap: 1.5rem;
  }

  .newsLetterTitle {
    composes: newsLetterTitle from './Footer.module.css';
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 700;
  }

  .newsLetterButton {
    composes: newsLetterButton from './Footer.module.css';
    width: 100%;
  }

  .newsLetterDescription {
    composes: newsLetterDescription from './Footer.module.css';
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .partnersContainer{
    composes: partnersContainer from './Footer.module.css';
    align-items: flex-start;
  }
}
