.languageSwitcherButton {
  display: flex;
  align-items: center;
  background: transparent;
  cursor: pointer;
  outline: none;
}

.languageSwitcherButton img {
  filter: var(--header-icon-filter-color);
}

.languageSwitcherButton:focus {
  outline: none;
}

.languageSwitcherButton p {
  margin: 0 8px;
  font-size: 0.875rem;
  color: var(--text-body-color);
}

.languageSwitcherListContainer {
  background: var(--background-color);
  border-radius: 0.5rem;
}

.languageSwitcherList {
  background: #fff;
  border: 1px solid var(--separator-color);
  border-radius: 0.5rem;
  padding: 0.25rem;
  min-width: 100%;
  width: auto;
  box-shadow:
    0px 10px 20px var(--shadow-color),
    0px 2px 6px var(--shadow-color),
    0px 0px 1px var(--shadow-color);
}

.languageSwitcherList:focus {
  outline: none;
}

.languageSwitcherListElement {
  display: flex;
  column-gap: 8px;
  color: var(-text-body-color);
  padding: 0.5rem;
  color: #000;
  border-radius: 2px;
}

.languageSwitcherListElement[aria-current] {
  pointer-events: none;
  background-color: var(--selected-color);
  color: var(--text-body-color);
}

.languageSwitcherListElement:hover {
  background: var(--selected-color);
  border-radius: 0.25rem;
  cursor: pointer;
  color: var(--text-body-color);
}

@media (max-width: 768px) {
  .languageSwitcherButton {
    width: 100%;
    height: 30px;
  }
  .languageSwitcherButton img {
    margin-left: auto;
  }

  .languageSwitcherButton p {
    margin: 0 12px;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.03em;
    font-weight: 700;
  }
  .languageSwitcherList {
    box-shadow: none;
    border: none;
    background: var(--background-color);
  }
  .languageSwitcherListElement {
    padding: 0.5rem;
    padding-bottom: 0.75rem;
    color: var(--text-body-color);
  }
}
